import { toast } from "react-toastify";
import { isBrowser } from "../utils";

export const addItem = (product) => {
  return (dispatch, getState) => {
    const items = getState().cart.cart;
    // Check if item exist
    const checkIndex =
      items &&
      items.findIndex((item) => {
        return item.id === product.id;
      });
    if (checkIndex !== -1) {
      // replace quantity if item exist
      var newItems = items.map((item) =>
        item.id === product.id ? { ...item, qty: product.qty } : item
      );
    }
    dispatch(setCart(newItems || [...items, product]));
    toast(`${product.qty} x ${product.name} added to cart!`, {
      toastId: product.qty + product.id,
    });
  };
};

export const removeItem = (id) => {
  return (dispatch, getState) => {
    const items = getState().cart.cart;
    const newItems = items.filter((item) => item.id !== id);

    dispatch(setCart(newItems));
  };
};

export const incQty = (id) => {
  return (dispatch, getState) => {
    const availableStock = getState().products.products.find(
      (item) => item.productID === id
    ).stock;

    const items = getState().cart.cart;
    const qtyInCart = items.find((item) => item.id === id).qty;

    if (qtyInCart < availableStock) {
      var newItems = items.map((item) => {
        if (item.id === id) {
          return { ...item, qty: item.qty + 1 };
        }
        return item;
      });
      dispatch(setCart(newItems));
    } else {
      toast.error(`Hurry, there's only ${availableStock} left in stock!`, {
        toastId: id + qtyInCart,
      });
    }
  };
};

export const decrQty = (id) => {
  return (dispatch, getState) => {
    const items = getState().cart.cart;
    const qtyInCart = items.find((item) => item.id === id).qty;

    if (qtyInCart === 1) {
      return;
    } else {
      var newItems = items.map((item) => {
        if (item.id === id) {
          return { ...item, qty: item.qty - 1 };
        }
        return item;
      });
      dispatch(setCart(newItems));
    }
  };
};

const setCart = (cart) => {
  return (dispatch) => {
    isBrowser() && localStorage.setItem("cart", JSON.stringify(cart));
    dispatch({
      type: `SET_CART`,
      data: cart,
    });
  };
};

export const clearCart = () => {
  return (dispatch) => {
    isBrowser() && localStorage.removeItem("cart");
    dispatch({
      type: `CLEAR_CART`,
    });
  };
};
