import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Img from "gatsby-image/withIEPolyfill";
import { Container, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Layout from "../components/layout";
import { FiMinus, FiPlus } from "react-icons/fi";
import SupplementsPg from "../components/landing/supplementsPg";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { addItem } from "../state/actions/cart";
import { getProducts } from "../state/actions/products";
import convertNumber from "../utils/convertNumber";

import styles from "../styles/templates/supplement.module.scss";
import SEO from "../components/seo";

export const query = graphql`
  query ($slug: String!) {
    contentfulSupplement(slug: { eq: $slug }) {
      id
      contentful_id
      category
      slug
      name
      flavor
      supplier
      description {
        json
      }
      otherFlavors {
        slug
        flavor
        flavorIcon {
          file {
            url
          }
        }
      }
      productImages {
        file {
          url
        }
        fixed(width: 500, height: 500) {
          ...GatsbyContentfulFixed
        }
      }
      flavorIcon {
        file {
          url
        }
      }
    }
  }
`;

const Supplement = (props) => {
  const [mainImage, setMainImage] = useState(
    props.data.contentfulSupplement.productImages[0].fixed
  );

  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(null);
  const [stock, setStock] = useState(0);

  const addItemHandler = () => {
    const item = {
      id: props.data.contentfulSupplement.contentful_id,
      name: props.data.contentfulSupplement.name,
      flavor: props.data.contentfulSupplement.flavor,
      qty: quantity,
      price: price,
      img: props.data.contentfulSupplement.productImages[0].fixed,
    };

    props.addItem(item);
  };

  const hydrateProduct = () => {
    if (props.products.length !== 0) {
      const details = props.products.find(
        (item) =>
          item.productID === props.data.contentfulSupplement.contentful_id
      );

      setPrice(details.price);
      setStock(details.stock);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    props.getProducts();
    hydrateProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    hydrateProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.products]);

  const flavorTooltip = (flavor) => {
    return (
      <Tooltip id="tooltip">
        <strong>{flavor}</strong>
      </Tooltip>
    );
  };

  return (
    <Layout>
      <SEO
        title={props.data.contentfulSupplement.name}
        description={
          props.data.contentfulSupplement.supplier +
          " " +
          props.data.contentfulSupplement.name +
          " - " +
          props.data.contentfulSupplement.flavor
        }
        article={false}
        pathname={`/supplements/${props.data.contentfulSupplement.slug}`}
        product={{
          brand: props.data.contentfulSupplement.supplier,
          stock: stock,
          price: price,
          id: props.data.contentfulSupplement.contentful_id,
          category: props.data.contentfulSupplement.category,
        }}
        templateImage={
          props.data.contentfulSupplement.productImages[0].file.url
        }
      />
      {/* Product Detail Section */}
      <Container className={styles.container}>
        <div className={`${styles.product} mt-5`}>
          <div className={styles.productImages}>
            <div className={styles.imageMain}>
              <Img
                fixed={mainImage}
                alt={props.data.contentfulSupplement.name}
                style={{ width: "100%", height: "100%" }}
                objectFit="contain"
                objectPosition="center"
              />
            </div>
            {props.data.contentfulSupplement.productImages.length > 1 &&
              props.data.contentfulSupplement.productImages.map(
                (image, idx) => (
                  <div
                    role="button"
                    key={idx}
                    className={styles.imageThumb}
                    onKeyDown={() => setMainImage(image.fixed)}
                    onClick={() => setMainImage(image.fixed)}
                    tabIndex={-1}
                  >
                    <Img
                      fixed={image.fixed}
                      alt={`${idx} image`}
                      style={{ width: "100%", height: "100%" }}
                      objectFit="contain"
                      objectPosition="center"
                    />
                  </div>
                )
              )}
          </div>

          {/* Content */}
          <div className={`px-3 overflow-auto`}>
            <p className={`${styles.supplier} fw-bold mb-0`}>
              <span className={`fw-lighter`}>Manufactured by </span>
              {props.data.contentfulSupplement.supplier}
            </p>

            <h1 className={`${styles.productName} display-3`}>
              {props.data.contentfulSupplement.name}
            </h1>
            <div className={`d-flex align-items-center mt-3`}>
              {props.data.contentfulSupplement.flavorIcon && (
                <img
                  src={props.data.contentfulSupplement.flavorIcon.file.url}
                  alt={props.data.contentfulSupplement.flavor}
                  style={{ width: "50px", borderRadius: "50%" }}
                />
              )}

              <p className="m-0 ms-4 h3">
                {props.data.contentfulSupplement.flavor}
              </p>
            </div>

            <div className={`${styles.verticalScroll} mb-4`}>
              {/* Product Description */}
              <div className={`${styles.productDescription}  small`}>
                {documentToReactComponents(
                  props.data.contentfulSupplement.description.json
                )}
              </div>
            </div>

            {props.data.contentfulSupplement.otherFlavors && (
              <div className="mt-5 mb-5">
                <h4 className="mb-4 small text-muted fs-5 text-uppercase">
                  Multiple Flavors Available
                </h4>
                <div className="d-flex align-items-start gap-3 overflow-auto pb-2">
                  {[
                    ...props.data.contentfulSupplement.otherFlavors,
                    {
                      selected: true,
                      slug: props.data.contentfulSupplement.slug,
                      flavor: props.data.contentfulSupplement.flavor,
                      flavorIcon: {
                        file: {
                          url: props.data.contentfulSupplement.flavorIcon.file
                            .url,
                        },
                      },
                    },
                  ]
                    .sort((a, b) => {
                      var textA = a.flavor.toUpperCase();
                      var textB = b.flavor.toUpperCase();
                      return textA < textB ? -1 : textA > textB ? 1 : 0;
                    })
                    .map((item) => (
                      <Link
                        to={`/shop/${item.slug}`}
                        className="text-center"
                        key={item.slug}
                      >
                        <OverlayTrigger
                          key={item.flavor}
                          placement="top"
                          overlay={flavorTooltip(item.flavor)}
                        >
                          <div
                            className={
                              item.selected
                                ? `${styles.flavorImage} ${styles.selected}`
                                : `${styles.flavorImage}`
                            }
                          >
                            <img
                              src={item.flavorIcon.file.url}
                              alt={item.flavor}
                              style={{ width: "100%" }}
                            />
                          </div>
                        </OverlayTrigger>
                      </Link>
                    ))}
                </div>
              </div>
            )}

            {price > 0 && (
              <h2 className={`${styles.price} mt-5 mb-5`}>
                LKR {convertNumber(price * quantity)}
              </h2>
            )}

            {stock > 0 ? (
              <div
                className={`d-lg-flex align-items-center justify-space-between ${styles.quantityDiv}`}
              >
                <div
                  className={styles.quantityBtn}
                  onClick={() => {
                    quantity <= 1 ? setQuantity(1) : setQuantity(quantity - 1);
                  }}
                  onKeyDown={() => {
                    quantity <= 1 ? setQuantity(1) : setQuantity(quantity - 1);
                  }}
                  role="button"
                  tabIndex={-2}
                >
                  <FiMinus />
                </div>
                <span className={styles.quantity}>{quantity}</span>
                <div
                  className={styles.quantityBtn}
                  onClick={() => {
                    if (quantity >= stock) {
                      toast.error(
                        `Hurry, there's only ${stock} left in stock!`,
                        {
                          toastId:
                            props.data.contentfulSupplement.id + quantity,
                        }
                      );
                      return;
                    } else {
                      setQuantity(quantity + 1);
                    }
                  }}
                  onKeyDown={() => {
                    if (quantity >= stock) {
                      toast.error(
                        `Hurry, there's only ${stock} left in stock!`,
                        {
                          toastId:
                            props.data.contentfulSupplement.id + quantity,
                        }
                      );
                      return;
                    } else {
                      setQuantity(quantity + 1);
                    }
                  }}
                  role="button"
                  tabIndex={-3}
                >
                  <FiPlus />
                </div>
                <Button
                  variant="add-to-cart"
                  size="lg"
                  className="ms-5"
                  onClick={() => {
                    addItemHandler();
                  }}
                >
                  Add to Cart
                </Button>
              </div>
            ) : (
              <h4 className={`${styles.noStock} mt-5`}>
                Currently Out Of Stock!
              </h4>
            )}
          </div>
        </div>
      </Container>

      {/* Q&A Section */}
      {/* <div className={styles.qaSection}>
        <Container>
          <div className={styles.qaPlaceHolder}>
            <RiQuestionAnswerLine
              style={{
                color: "#d5d5d5",
                fontSize: "10rem",
                marginBottom: "30px",
              }}
            />
            <div>There are no questions yet.</div>
            <div>
              <span>Login</span> or <span>Register</span> to ask the seller now
              and answer will be shown here.
            </div>
          </div>
        </Container>
      </div> */}

      {/* Related Products */}
      <SupplementsPg title="Related Products" />
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    products: state.products.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addItem: (item) => dispatch(addItem(item)),
    getProducts: () => dispatch(getProducts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Supplement);
